import CartInfoDropDownContent from '~/themes/base/components/cart/CartInfoDropDownContent'

export default {
  mixins: [CartInfoDropDownContent],
  computed: {
    itemsCount: function() {
      const cartItems = this.$store.state.cart.cartData.items
      if (cartItems) {
        return this.$store.state.cart.cartData.items.length
      } else {
        return 0
      }
    },
  },
}
