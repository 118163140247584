import CheckoutItemLine from '~/themes/base/components/cart/CheckoutItemLine/CheckoutItemLine'

export default {
  extends: CheckoutItemLine,
  computed: {
    itemFormattedPrices() {
      return this.getFormattedPrices({ amount: this.item.amount, prices: this.item.prices })
    },
  },
}
